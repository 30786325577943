import React from 'react';
import { useSelector } from 'react-redux';

// material-ui
import { makeStyles, useTheme } from '@material-ui/styles';
import {
    Avatar,
    CardContent,
    Chip,
    ClickAwayListener,
    Divider,
    Grid,
    List,
    ListItemIcon,
    ListItemText,
    Paper,
    Popper,
    Typography,
    Dialog,
    InputLabel,
    OutlinedInput,
    FormControl,
    FormHelperText,
    ListItemButton,
    Button
} from '@material-ui/core';
import { useDispatch } from 'react-redux';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
import { jwtDecode } from 'jwt-decode';
import { Formik } from 'formik';
import * as Yup from 'yup';

// assets
import { IconLogout, IconSettings, IconKey } from '@tabler/icons';
import Transitions from "../../../../ui-component/extended/Transitions";
import MainCard from "../../../../ui-component/cards/MainCard";
import SubCard from "../../../../ui-component/cards/SubCard";
import AnimateButton from "../../../../ui-component/extended/AnimateButton";
import useAuth from "../../../../hooks/useAuth";
import useScriptRef from "../../../../hooks/useScriptRef";
import axiosServices from "../../../../utils/axios";
import {SNACKBAR_OPEN} from "../../../../store/actions";
import apiUrl from "../../../../utils/api";

// style const
const useStyles = makeStyles((theme) => ({
    profileDiv: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'end',
        height: '100%'
    },
    navContainer: {
        width: '100%',
        maxWidth: '350px',
        minWidth: '300px',
        backgroundColor: theme.palette.background.paper,
        borderRadius: '10px',
        [theme.breakpoints.down('sm')]: {
            minWidth: '100%'
        }
    },
    headerAvatar: {
        cursor: 'pointer',
        ...theme.typography.mediumAvatar,
        margin: '8px 0 8px 8px !important'
    },
    profileChip: {
        height: '48px',
        alignItems: 'center',
        borderRadius: '27px',
        transition: 'all .2s ease-in-out',
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
        '&[aria-controls="menu-list-grow"], &:hover': {
            borderColor: theme.palette.primary.main,
            background: `${theme.palette.primary.main} !important`,
            color: theme.palette.primary.light,
            '& svg': {
                stroke: theme.palette.primary.light
            }
        }
    },
    profileLabel: {
        lineHeight: 0,
        padding: '12px'
    },
    listItem: {
        marginTop: '5px'
    },
    cardContent: {
        padding: '16px !important'
    },
    card: {
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark[800] : theme.palette.primary.light,
        marginBottom: '16px',
        marginTop: '16px'
    },
    searchControl: {
        width: '100%',
        paddingRight: '8px',
        paddingLeft: '16px',
        marginBottom: '16px',
        marginTop: '16px'
    },
    startAdornment: {
        fontSize: '1rem',
        color: theme.palette.grey[500]
    },
    flex: {
        display: 'flex'
    },
    name: {
        marginLeft: '2px',
        fontWeight: 400
    },
    ScrollHeight: {
        height: '100%',
        maxHeight: 'calc(100vh - 250px)',
        overflowX: 'hidden'
    },
    badgeWarning: {
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.dark : theme.palette.warning.dark,
        color: '#fff'
    },
    loginInput: {
        ...theme.typography.customInput,
        width: '450px'
    }
}));

const ProfileSection = () => {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const customization = useSelector((state) => state.customization);
    const { logout } = useAuth();
    const scriptedRef = useScriptRef();
    const serviceToken = window.localStorage.getItem('serviceTokenAdmin');
    let decoded;
    let userCode;
    if (serviceToken) {
        decoded = jwtDecode(serviceToken);
        userCode = decoded.sub;
    }
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [isModalOpen, setModalOpen] = React.useState(false);

    const handleLogout = async () => {
        try {
            await logout();
        } catch (err) {
            console.error(err);
        }
    };

    const handleToggle = () => setOpen((prevOpen) => !prevOpen);
    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    const prevOpen = React.useRef(open);

    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    const closeChangePasswordModal = () => {
        setModalOpen(false);
    };

    const openChangePasswordModal = () => {
        setModalOpen(true);
    };

    const changeCurrentPassword = async (newPassword, newPasswordConfirmation) => {
        try {
            await axiosServices.patch(apiUrl + `/api/v1/users/password`, {userCode, password: newPassword, confirmation: newPasswordConfirmation});
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'Success!',
                variant: 'alert',
                alertSeverity: 'success'
            });
            closeChangePasswordModal();
        } catch (e) {
            console.log(e);
            if (e === 'Wrong Services') {
                window.location.reload();
            }
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: e.message,
                variant: 'alert',
                alertSeverity: 'error'
            });
        }
    };

    return (
        <div className={classes.profileDiv}>
            <Chip
                classes={{ label: classes.profileLabel }}
                className={classes.profileChip}
                icon={
                    <Avatar
                        className={classes.headerAvatar}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        color="inherit"
                    />
                }
                label={<IconSettings stroke={1.5} size="1.5rem" color={theme.palette.primary.main} />}
                variant="outlined"
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                color="primary"
            />
            <Popper
                placement="bottom-end"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 14]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions position="top-right" in={open} {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                    <CardContent className={classes.cardContent}>
                                        <Grid container direction="column" spacing={0}>
                                            <Grid item>
                                                <Typography variant="subtitle2">Administrator</Typography>
                                            </Grid>
                                        </Grid>
                                        <Divider />
                                        <PerfectScrollbar className={classes.ScrollHeight}>
                                            <Divider />
                                            <List component="nav" className={classes.navContainer}>
                                                <ListItemButton
                                                    className={classes.listItem}
                                                    sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                    selected={false}
                                                    onClick={handleLogout}
                                                >
                                                    <ListItemIcon>
                                                        <IconLogout stroke={1.5} size="1.3rem" />
                                                    </ListItemIcon>
                                                    <ListItemText primary={<Typography variant="body2">Logout</Typography>} />
                                                </ListItemButton>
                                                <ListItemButton
                                                    className={classes.listItem}
                                                    sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                    onClick={openChangePasswordModal}
                                                >
                                                    <ListItemIcon>
                                                        <IconKey stroke={1.5} />
                                                    </ListItemIcon>
                                                    <ListItemText primary={<Typography variant="body2">Change password</Typography>} />
                                                </ListItemButton>
                                            </List>
                                        </PerfectScrollbar>
                                    </CardContent>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
            <Dialog maxWidth="sm" fullWidth onClose={closeChangePasswordModal} open={isModalOpen} sx={{ '& .MuiDialog-paper': { p: 0 } }}>
                <Grid item xs={12}>
                    <SubCard title="Change password">
                        <Formik
                            initialValues={{
                                // currentPassword: '',
                                newPassword: '',
                                newPasswordConfirmation: '',
                                submit: null
                            }}
                            validationSchema={Yup.object().shape({
                                // currentPassword: Yup.string().max(255).required('Current password is required'),
                                newPassword: Yup.string().max(255).required('New password is required'),
                                newPasswordConfirmation: Yup.string().when('newPassword', {
                                    is: (val) => val && val.length > 0,
                                    then: Yup.string().oneOf(
                                        [Yup.ref('newPassword')],
                                        'Please enter the same password!'
                                    )
                                })
                            })}
                            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                                try {
                                    await changeCurrentPassword(values.newPassword, values.newPasswordConfirmation);

                                    if (scriptedRef.current) {
                                        setStatus({success: true});
                                        setSubmitting(false);
                                    }
                                } catch (err) {
                                    console.error(err);
                                    if (scriptedRef.current) {
                                        setStatus({success: false});
                                        setErrors({submit: err.message});
                                        setSubmitting(false);
                                    }
                                }
                            }}
                        >
                            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                                <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                                    <FormControl
                                        fullWidth
                                        error={Boolean(touched.newPassword && errors.newPassword)}
                                        className={classes.loginInput}
                                    >
                                        <InputLabel htmlFor="outlined-adornment-new-password">New password</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-new-password"
                                            type="password"
                                            value={values.newPassword}
                                            name="newPassword"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            inputProps={{
                                                classes: {
                                                    notchedOutline: classes.notchedOutline
                                                }
                                            }}
                                        />
                                    </FormControl>

                                    {touched.newPassword && errors.newPassword && (
                                        <FormControl fullWidth>
                                            <FormHelperText error id="standard-weight-helper-text-confirm-password">
                                                {' '}
                                                {errors.newPassword}{' '}
                                            </FormHelperText>
                                        </FormControl>
                                    )}

                                    <FormControl
                                        fullWidth
                                        error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                                        className={classes.loginInput}
                                    >
                                        <InputLabel htmlFor="outlined-adornment-confirm-new-password">Confirm new password</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-confirm-new-password"
                                            type="password"
                                            value={values.newPasswordConfirmation}
                                            name="newPasswordConfirmation"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            inputProps={{
                                                classes: {
                                                    notchedOutline: classes.notchedOutline
                                                }
                                            }}
                                        />
                                    </FormControl>

                                    {touched.newPasswordConfirmation && errors.newPasswordConfirmation && (
                                        <FormControl fullWidth>
                                            <FormHelperText error id="standard-weight-helper-text-confirm-password">
                                                {' '}
                                                {errors.newPasswordConfirmation}{' '}
                                            </FormHelperText>
                                        </FormControl>
                                    )}
                                    <Grid spacing={2} container justifyContent="flex-end" sx={{ mt: 3 }}>
                                        <Grid item>
                                            <AnimateButton>
                                                <Button
                                                    variant="contained"
                                                    disabled={isSubmitting}
                                                    type="submit"
                                                >
                                                    Change password
                                                </Button>
                                            </AnimateButton>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Formik>
                    </SubCard>
                </Grid>
            </Dialog>

        </div>
    );
};

export default ProfileSection;
