import PropTypes from 'prop-types';
import React, { createContext, useEffect, useReducer } from 'react';

// third-party

// reducer - state management
import { ACCOUNT_INITIALIZE, LOGIN, LOGOUT } from 'store/actions';
import accountReducer from 'store/accountReducer';

// project imports
import axios from 'utils/axios';
import Loader from 'ui-component/Loader';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import apiUrl from "../utils/api";

// constant
const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

const verifyToken = (serviceToken) => {
    if (!serviceToken) {
        return false;
    }
    const decoded = jwtDecode(serviceToken);
    return decoded.exp > Date.now() / 1000;
};

const setSession = (serviceToken) => {
    if (serviceToken) {
        localStorage.setItem('serviceToken', serviceToken);
        axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    } else {
        localStorage.removeItem('serviceToken');
        delete axios.defaults.headers.common.Authorization;
    }
};

// ===========================|| JWT CONTEXT & PROVIDER ||=========================== //

const JWTContext = createContext({
    ...initialState,
    login: () => Promise.resolve(),
    logout: () => {}
});

export const JWTProvider = ({ children }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);
    const navigate = useNavigate();

    const login = async () => {
        if (window.location.pathname === '/') {
            navigate('/welcome', { replace: true });
        } else {
            navigate(window.location.pathname, { replace: true });
        }
        dispatch({
            type: LOGIN,
            payload: {}
        });
    };

    const logout = () => {
        setSession(null);
        navigate('/', { replace: true });
        dispatch({ type: LOGOUT });
    };

    useEffect(() => {
        const init = async () => {
            try {
                const serviceToken = window.localStorage.getItem('serviceTokenAdmin');
                if (serviceToken && verifyToken(serviceToken)) {
                    setSession(serviceToken);
                    if (window.location.pathname === '/') {
                        navigate('welcome', { replace: true });
                    } else {
                        navigate(window.location.pathname, { replace: true });
                    }
                    dispatch({
                        type: ACCOUNT_INITIALIZE,
                        payload: {
                            isLoggedIn: true
                        }
                    });
                } else {
                    dispatch({
                        type: ACCOUNT_INITIALIZE,
                        payload: {
                            isLoggedIn: true
                        }
                    });
                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: ACCOUNT_INITIALIZE,
                    payload: {
                        isLoggedIn: false,
                        user: null
                    }
                });
            }
        };

        init();
    }, []);

    if (!state.isInitialized) {
        return <Loader />;
    }

    return <JWTContext.Provider value={{ ...state, login, logout }}>{children}</JWTContext.Provider>;
};

JWTProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export default JWTContext;
