import PropTypes from 'prop-types';
import * as React from 'react';
import {useEffect, useState} from 'react';

// material-ui
import {
    Grid,
    Typography,
    TextField,
    FormControlLabel, RadioGroup, Radio, Divider, Autocomplete, Stack, Button
} from '@material-ui/core';
import countries from "../countries";
import AnimateButton from "../../../../../ui-component/extended/AnimateButton";
import MainCard from "../../../../../ui-component/cards/MainCard";

// ===========================|| FORM WIZARD - VALIDATION  ||=========================== //
const RiskManagement = () => {
    const [questionOne, setQuestionOne] = useState(false);
    const [questionOneResult, setQuestionOneResult] = useState('');

    const [questionTwo, setQuestionTwo] = useState(false);
    const [questionTwoResult, setQuestionTwoResult] = useState('');

    const [questionThree, setQuestionThree] = useState(false);
    const [questionThreeResult, setQuestionThreeResult] = useState('');

    const [questionFour, setQuestionFour] = useState(false);
    const [questionFourResult, setQuestionFourResult] = useState('');

    const [questionFive, setQuestionFive] = useState(false);
    const [questionFiveResult, setQuestionFiveResult] = useState('');

    const [questionSix, setQuestionSix] = useState(false);
    const [questionSixResult, setQuestionSixResult] = useState('');

    const [questionSeven, setQuestionSeven] = useState('');
    const [questionSevenResult, setQuestionSevenResult] = useState('');

    const [questionEight, setQuestionEight] = useState('');
    const [questionEightResult, setQuestionEightResult] = useState('');
    const [questionNine, setQuestionNine] = useState('');
    const [questionNineResult, setQuestionNineResult] = useState('');
    const [questionTen, setQuestionTen] = useState('');
    const [questionTenResult, setQuestionTenResult] = useState('');

    const [question11, setQuestion11] = useState('');
    const [question11Result, setQuestion11Result] = useState('');

    const [question12, setQuestion12] = useState('');
    const [question12Result, setQuestion12Result] = useState('');

    const [question13, setQuestion13] = useState('');
    const [question13Result, setQuestion13Result] = useState('');

    const [question14, setQuestion14] = useState('');
    const [question14Result, setQuestion14Result] = useState('');

    const [question15, setQuestion15] = useState('');
    const [question15Result, setQuestion15Result] = useState('');
    const [comment, setComment] = useState('');
    const [comment2, setComment2] = useState('');
    const [comment3, setComment3] = useState('');
    const [comment4, setComment4] = useState('');
    const [comment5, setComment5] = useState('');
    const [subCategory1, setSubCategory1] = useState('Automatic High');
    const [showSubCategory1, setShowSubCategory1] = useState(false);
    const [subCategory2, setSubCategory2] = useState('Automatic High');
    const [showSubCategory2, setShowSubCategory2] = useState(false);
    const [subCategory3, setSubCategory3] = useState('Low');
    const [showSubCategory3, setShowSubCategory3] = useState(false);
    const [subCategory4, setSubCategory4] = useState('Medium');
    const [showSubCategory4, setShowSubCategory4] = useState(false);
    const [finalRisk, setFinalRisk] = useState('Acceptable');
    const [showFinalRisk, setShowFinalRisk] = useState(false);
    const [showApproveButton, setShowApproveButton] = useState(false);
    const [showSaveButton, setShowSaveButton] = useState(true);
    const [showCommitButton, setShowCommitButton] = useState(false);
    const [showFillerName, setShowFillerName] = useState(false);
    const [showApproverName, setShowAproverName] = useState(false);
    const [allReadOnly, setAllReadOnly] = useState(false);
    const [currentTimeForSave, setCurrentTimeForSave] = useState(new Date());
    const [currentTimeForApprove, setCurrentTimeForApprove] = useState(new Date());

    const handleQuestion1 = (e) => {
        setQuestionOne(e.target.value);
        if (e.target.value === 'Yes') {
            setQuestionOneResult('Acceptable');
        } else if (e.target.value === 'No') {
            setQuestionOneResult('Unacceptable');
        }
    };
    const handleQuestion2 = (e) => {
        setQuestionTwo(e.target.value);
        if (e.target.value === 'Yes') {
            setQuestionTwoResult('Acceptable');
        } else if (e.target.value === 'No') {
            setQuestionTwoResult('Unacceptable');
        }
    };
    const handleQuestion3 = (e) => {
        setQuestionThree(e.target.value);
        if (e.target.value === 'Yes') {
            setQuestionThreeResult('Acceptable');
        } else if (e.target.value === 'No') {
            setQuestionThreeResult('Unacceptable');
        }
    };
    const handleQuestion4 = (e) => {
        setQuestionFour(e.target.value);
        if (e.target.value === 'Yes') {
            setQuestionFourResult('Acceptable');
        } else if (e.target.value === 'No') {
            setQuestionFourResult('Unacceptable');
        }
    };
    const handleQuestion5 = (e) => {
        setQuestionFive(e.target.value);
        if (e.target.value === 'Yes') {
            setQuestionFiveResult('Acceptable');
        } else if (e.target.value === 'No') {
            setQuestionFiveResult('Unacceptable');
        }
    };
    const handleQuestion6 = (e) => {
        setQuestionSix(e.target.value);
        if (e.target.value === 'Yes') {
            setQuestionSixResult('Acceptable');
        } else if (e.target.value === 'No') {
            setQuestionSixResult('Unacceptable');
        }
    };

    const handleQuestion7 = (value) => {
        setQuestionSeven(value);
        if (value.value === 0) {
            setQuestionSevenResult('High');
        } else if (value.value === 1) {
            setQuestionSevenResult('Medium');
        } else if (value.value === 2) {
            setQuestionSevenResult('Low');
        }
    };

    const handleQuestion8 = (value) => {
        setQuestionEight(value);
        if (value.value === 0 || value.value === 3) {
            setQuestionEightResult('High');
        } else if (value.value === 1) {
            setQuestionEightResult('Medium');
        } else if (value.value === 2) {
            setQuestionEightResult('Low');
        }
    };
    const handleQuestion9 = (value) => {
        setQuestionNine(value);
        if (value.value === 0) {
            setQuestionNineResult('Automatic High');
        } else if (value.value === 1) {
            setQuestionNineResult('Low');
        } else if (value.value === 2) {
            setQuestionNineResult('Not Applicable');
        }
    };
    const handleQuestion10 = (e) => {
        setQuestionTen(e.target.value);
        if (e.target.value === 'Yes') {
            setQuestionTenResult('Acceptable');
        } else if (e.target.value === 'No') {
            setQuestionTenResult('Unacceptable');
        }
    };
    const handleQuestion11 = (value) => {
        setQuestion11(value);
        if (value.value === 0) {
            setQuestion11Result('Automatic High');
        } else if (value.value === 1) {
            setQuestion11Result('Low');
        } else if (value.value === 2) {
            setQuestion11Result('Not Applicable');
        }
        setShowSubCategory1(true);
    };
    const handleQuestion12 = (value) => {
        setQuestion12(value);
        if (value.value === 0) {
            setQuestion12Result('Low');
        } else if (value.value === 1) {
            setQuestion12Result('Medium');
        } else if (value.value === 2) {
            setQuestion12Result('High');
        } else if (value.value === 3) {
            setQuestion12Result('Automatic High');
        }
    };

    const handleQuestion13 = (value) => {
        setQuestion13(value);
        if (value.value === 0) {
            setQuestion13Result('Low');
        } else if (value.value === 1) {
            setQuestion13Result('Medium');
        } else if (value.value === 2) {
            setQuestion13Result('High');
        } else if (value.value === 3) {
            setQuestion13Result('Automatic High');
        }
        setShowSubCategory2(true);
    };

    const handleQuestion14 = (value) => {
        setQuestion14(value);
        if (value.value === 0) {
            setQuestion14Result('Low');
        } else if (value.value === 1) {
            setQuestion14Result('Medium');
        } else if (value.value === 2) {
            setQuestion14Result('High');
        } else if (value.value === 3) {
            setQuestion14Result('Unacceptable');
        }
        setShowSubCategory3(true);
    };

    const handleQuestion15 = (value) => {
        setQuestion15(value);
        if (value.value === 0) {
            setQuestion15Result('High');
        } else if (value.value === 1) {
            setQuestion15Result('Low');
        } else if (value.value === 2) {
            setQuestion15Result('Not Applicable');
        }
        setShowSubCategory4(true);
        setShowFinalRisk(true);
    };

    const handleSave = () => {
        setShowCommitButton(true);
        setShowSaveButton(false);
    };
    const handleCommit = () => {
        setShowApproveButton(true);
        setShowCommitButton(false);
        setShowFillerName(true);
    };
    const handleApprove = () => {
        setCurrentTimeForApprove(new Date());
        setShowApproveButton(false);
        setShowAproverName(true);
        setAllReadOnly(true);
    };

    return (
        <MainCard>
            <Grid container xs={12}>
            <Grid container sm={1}></Grid>
            <Grid container sm={10}>

                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            CONDITIONS DESIGNED BY RETAIL
                        </Typography>
                        <Divider sx={{ borderColor: 'grey', mt: 2, mb: 2, borderWidth: '1px' }} />
                    </Grid>
                    {/* Question 1 */}
                    <Grid item xs={4}>
                        <Typography variant="h6" id="nomineeShareholder">
                            The customer and UBO are not listed on a sanction list or other applicable lists.
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <RadioGroup
                            row
                            aria-labelledby="nomineeShareholder"
                            name="nomineeShareholder"
                            onChange={handleQuestion1}
                        >
                            <FormControlLabel disabled={allReadOnly} value="Yes" control={<Radio color="secondary"/>} label="Yes" />
                            <FormControlLabel disabled={allReadOnly} value="No" control={<Radio color="secondary"/>} label="No" />
                        </RadioGroup>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            name="questionOneResult"
                            label="Result"
                            value={questionOneResult}
                            readOnly
                            size="small"
                        />
                    </Grid>
                    {/* Question 2 */}
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" id="nomineeShareholder">
                            The customer is registered in KvK and resident in the Netherlands.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <RadioGroup
                            row
                            aria-labelledby="nomineeShareholder"
                            name="nomineeShareholder"
                            onChange={handleQuestion2}
                        >
                            <FormControlLabel disabled={allReadOnly} value="Yes" control={<Radio color="secondary"/>} label="Yes" />
                            <FormControlLabel disabled={allReadOnly} value="No" control={<Radio color="secondary"/>} label="No" />
                        </RadioGroup>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            id="questionTwoResult"
                            name="questionTwoResult"
                            label="Result"
                            value={questionTwoResult}
                            readOnly
                            size="small"
                        />
                    </Grid>
                    {/* Question 3 */}
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" id="nomineeShareholder">
                            The customer has less than 50 employees and/or turnover is less than EUR 10 millions.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <RadioGroup
                            row
                            aria-labelledby="nomineeShareholder"
                            name="nomineeShareholder"
                            onChange={handleQuestion3}
                        >
                            <FormControlLabel disabled={allReadOnly} value="Yes" control={<Radio color="secondary"/>} label="Yes" />
                            <FormControlLabel disabled={allReadOnly} value="No" control={<Radio color="secondary"/>} label="No" />
                        </RadioGroup>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            id="customerIntroduction"
                            name="customerIntroduction"
                            label="Result"
                            value={questionThreeResult}
                            size="small"
                            readOnly
                        />
                    </Grid>
                    {/* Question 4 */}
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" id="nomineeShareholder">
                            There is less than 3 layers to reach the UBO.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <RadioGroup
                            row
                            aria-labelledby="nomineeShareholder"
                            name="nomineeShareholder"
                            onChange={handleQuestion4}
                        >
                            <FormControlLabel disabled={allReadOnly} value="Yes" control={<Radio color="secondary"/>} label="Yes" />
                            <FormControlLabel disabled={allReadOnly} value="No" control={<Radio color="secondary"/>} label="No" />
                        </RadioGroup>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            id="customerIntroduction"
                            name="customerIntroduction"
                            label="Result"
                            value={questionFourResult}
                            readOnly
                            size="small"
                            autoComplete="customer-introduction"
                        />
                    </Grid>
                    {/* Question 5 */}
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" id="nomineeShareholder">
                            All intermediary companies are registered in KvK
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <RadioGroup
                            row
                            aria-labelledby="nomineeShareholder"
                            name="nomineeShareholder"
                            onChange={handleQuestion5}
                        >
                            <FormControlLabel disabled={allReadOnly} value="Yes" control={<Radio color="secondary"/>} label="Yes" />
                            <FormControlLabel disabled={allReadOnly} value="No" control={<Radio color="secondary"/>} label="No" />
                        </RadioGroup>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            id="customerIntroduction"
                            name="customerIntroduction"
                            label="Result"
                            value={questionFiveResult}
                            readOnly
                            size="small"
                            autoComplete="customer-introduction"
                        />
                    </Grid>
                    {/* Question 6 */}
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" id="nomineeShareholder">
                            The UBO and the POA are resident in low risk jurisdictions.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <RadioGroup
                            row
                            aria-labelledby="nomineeShareholder"
                            name="nomineeShareholder"
                            onChange={handleQuestion6}
                        >
                            <FormControlLabel disabled={allReadOnly} value="Yes" control={<Radio color="secondary"/>} label="Yes" />
                            <FormControlLabel disabled={allReadOnly} value="No" control={<Radio color="secondary"/>} label="No" />
                        </RadioGroup>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            id="customerIntroduction"
                            name="customerIntroduction"
                            label="Result"
                            value={questionSixResult}
                            readOnly
                            size="small"
                            autoComplete="customer-introduction"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="h6" >
                            Comment
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            id="customerIntroduction"
                            name="customerIntroduction"
                            label="Comment"
                            disabled={allReadOnly}
                            value={comment}
                            onChange={(e)=> setComment(e.target.value)}
                            readOnly
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            CUSTOMER RISK
                        </Typography>
                        <Divider sx={{ borderColor: 'grey', mt: 1, mb: 1, borderWidth: '1px' }} />
                    </Grid>
                    {/* Question 7 */}
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" id="nomineeShareholder">
                            What is the age of the company based on incorporation date?
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Autocomplete
                            disabled={allReadOnly}
                            disablePortal
                            id="birthCountry"
                            options={[{value: 0, label: "Less than or equal to 1 year"}, {
                                value: 1,
                                label: "More than 1 year and less than 3 years"
                            }, {value: 2, label: "More than or equal to 3 years"}]}
                            getOptionLabel={(option) => option.label}
                            filterSelectedOptions
                            onChange={(event, value) => handleQuestion7(value)}
                            size="small"
                            renderInput={(params) => <TextField {...params} label="" required />}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            id="customerIntroduction"
                            name="customerIntroduction"
                            label="Result"
                            value={questionSevenResult}
                            readOnly
                            size="small"
                        />
                    </Grid>
                    {/* Question 8 */}
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" id="nomineeShareholder">
                            What is the length of the customer relationship?
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Autocomplete
                            disabled={allReadOnly}
                            disablePortal
                            id="questionEight"
                            options={[{value: 0, label: "Less than or equal to 1 year"}, {
                                value: 1,
                                label: "More than 1 year and less than 3 years"
                            }, {value: 2, label: "More than or equal to 3 years"}, {value: 3, label: "Onboarding"}]}
                            getOptionLabel={(option) => option.label}
                            filterSelectedOptions
                            onChange={(event, value) => handleQuestion8(value)}
                            size="small"
                            renderInput={(params) => <TextField {...params} required />}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            id="customerIntroduction"
                            name="customerIntroduction"
                            label="Result"
                            value={questionEightResult}
                            readOnly
                            size="small"
                        />
                    </Grid>
                    {/* Question 9 */}
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" id="nomineeShareholder">
                            Any unusual pattern observed or any investigations conducted on Compliance side?
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Autocomplete
                            disabled={allReadOnly}
                            disablePortal
                            id="questionNine"
                            options={[{value: 0, label: "Yes"}, {
                                value: 1,
                                label: "No"
                            }, {value: 2, label: "Not applicable"}]}
                            getOptionLabel={(option) => option.label}
                            filterSelectedOptions
                            onChange={(event, value) => handleQuestion9(value)}
                            size="small"
                            renderInput={(params) => <TextField {...params} required />}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            id="customerIntroduction"
                            name="customerIntroduction"
                            label="Result"
                            value={questionNineResult}
                            readOnly
                            size="small"
                        />
                    </Grid>
                    {/* Question 10 */}
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" id="nomineeShareholder">
                            The customer's UBO is a PEP
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <RadioGroup
                            row
                            aria-labelledby="nomineeShareholder"
                            name="nomineeShareholder"
                            onChange={handleQuestion10}
                        >
                            <FormControlLabel disabled={allReadOnly} value="Yes" control={<Radio color="secondary"/>} label="Yes" />
                            <FormControlLabel disabled={allReadOnly} value="No" control={<Radio color="secondary"/>} label="No" />
                        </RadioGroup>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            id="customerIntroduction"
                            name="customerIntroduction"
                            label="Result"
                            value={questionTenResult}
                            readOnly
                            size="small"
                        />
                    </Grid>
                    {/* Question 11 */}
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" id="nomineeShareholder">
                            If the UBO is PEP, the Source of wealth is well explained and documented.
                            (Choose "NOT APPLICABLE" for customers who are not PEP)
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Autocomplete
                            disabled={allReadOnly}
                            disablePortal
                            id="questionNine"
                            options={[{value: 0, label: "Yes"}, {
                                value: 1,
                                label: "No"
                            }, {value: 2, label: "Not applicable"}]}
                            getOptionLabel={(option) => option.label}
                            filterSelectedOptions
                            onChange={(event, value) => handleQuestion11(value)}
                            size="small"
                            renderInput={(params) => <TextField {...params} required />}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            id="customerIntroduction"
                            name="customerIntroduction"
                            label="Result"
                            value={question11Result}
                            readOnly
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6">SUB CATEGORY RISK</Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}></Grid>
                    {showSubCategory1 === false && (
                        <Grid item xs={12} sm={4}></Grid>
                    )}
                    {showSubCategory1 === true && (
                        <Grid item xs={12} sm={4}>
                            <TextField
                                id="customerIntroduction"
                                name="customerIntroduction"
                                label="Sub Category Risk"
                                value={subCategory1}
                                disabled={allReadOnly}
                                readOnly
                                size="small"
                            />
                        </Grid>
                    )}
                    <Grid item sm={4}>
                        <Typography variant="h6">
                            Comment
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            id="customerIntroduction"
                            name="customerIntroduction"
                            label="Comment"
                            disabled={allReadOnly}
                            value={comment2}
                            onChange={(e)=> setComment2(e.target.value)}
                            readOnly
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            GEOGRAPHY RISK
                        </Typography>
                        <Divider sx={{ borderColor: 'grey', mt: 1, mb: 1, borderWidth: '1px' }} />
                    </Grid>
                    {/* Question 12 */}
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" id="nomineeShareholder">
                            What is the highest risk level of jurisdiction where the customer has business relations.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Autocomplete
                            disabled={allReadOnly}
                            disablePortal
                            id="birthCountry"
                            options={[{value: 0, label: "Low"}, {
                                value: 1,
                                label: "Medium"
                            }, {value: 2, label: "High"}, {value: 3, label: 'Blacklisted jurisdiction'}]}
                            getOptionLabel={(option) => option.label}
                            filterSelectedOptions
                            onChange={(event, value) => handleQuestion12(value)}
                            size="small"
                            renderInput={(params) => <TextField {...params} label="" required />}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            id="customerIntroduction"
                            name="customerIntroduction"
                            label="Result"
                            value={question12Result}
                            readOnly
                            size="small"
                        />
                    </Grid>
                    {/* Question 13 */}
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" id="nomineeShareholder">
                            What is the highest risk level of the nationality jurisdiction of the UBO/POA
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Autocomplete
                            disabled={allReadOnly}
                            disablePortal
                            id="birthCountry"
                            options={[{value: 0, label: "Low"}, {
                                value: 1,
                                label: "Medium"
                            }, {value: 2, label: "High"}, {value: 3, label: 'Blacklisted jurisdiction'}]}
                            getOptionLabel={(option) => option.label}
                            filterSelectedOptions
                            onChange={(event, value) => handleQuestion13(value)}
                            size="small"
                            renderInput={(params) => <TextField {...params} label="" required />}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            id="customerIntroduction"
                            name="customerIntroduction"
                            label="Result"
                            value={question13Result}
                            readOnly
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6">SUB CATEGORY RISK</Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}></Grid>
                    {showSubCategory2 === false && (
                        <Grid item xs={12} sm={4}></Grid>
                    )}
                    {showSubCategory2 === true && (
                        <Grid item xs={12} sm={4}>
                            <TextField
                                id="customerIntroduction"
                                name="customerIntroduction"
                                label="Sub Category Risk"
                                value={subCategory2}
                                disabled={allReadOnly}
                                readOnly
                                size="small"
                            />
                        </Grid>
                    )}
                    <Grid item xs={4}>
                        <Typography variant="h6" >
                            Comment
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            id="customerIntroduction"
                            name="customerIntroduction"
                            label="Comment"
                            disabled={allReadOnly}
                            value={comment3}
                            onChange={(e)=> setComment3(e.target.value)}
                            readOnly
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            SECTOR RISK
                        </Typography>
                        <Divider sx={{ borderColor: 'grey', mt: 1, mb: 1, borderWidth: '1px' }} />
                    </Grid>
                    {/* Question 14 */}
                    <Grid item xs={12} sm={4}>
                        <Stack direction="column">
                            <Typography variant="h6" id="nomineeShareholder">
                                What is the risk level of the sector involved?
                            </Typography>
                            <Typography variant="span2" id="nomineeShareholder">
                                <i>Please fill this question in line with underlying sectors for holding companies or similar entities.</i>
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Autocomplete
                            disabled={allReadOnly}
                            disablePortal
                            id="birthCountry"
                            options={[{value: 0, label: "Low"}, {
                                value: 1,
                                label: "Medium"
                            }, {value: 2, label: "High"}, {value: 3, label: 'Unacceptable'}]}
                            getOptionLabel={(option) => option.label}
                            filterSelectedOptions
                            onChange={(event, value) => handleQuestion14(value)}
                            size="small"
                            renderInput={(params) => <TextField {...params} label="" required />}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            id="customerIntroduction"
                            name="customerIntroduction"
                            label="Result"
                            value={question14Result}
                            readOnly
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6">SUB CATEGORY RISK</Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}></Grid>
                    {showSubCategory3 === false && (
                        <Grid item xs={12} sm={4}></Grid>
                    )}
                    {showSubCategory3 === true && (
                        <Grid item xs={12} sm={4}>
                            <TextField
                                id="customerIntroduction"
                                name="customerIntroduction"
                                label="Sub Category Risk"
                                value={subCategory3}
                                disabled={allReadOnly}
                                readOnly
                                size="small"
                            />
                        </Grid>
                    )}
                    <Grid item xs={4}>
                        <Typography variant="h6" >
                            Comment
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            id="customerIntroduction"
                            name="customerIntroduction"
                            label="Comment"
                            disabled={allReadOnly}
                            value={comment4}
                            onChange={(e)=> setComment4(e.target.value)}
                            readOnly
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            PRODUCT RISK
                        </Typography>
                        <Divider sx={{ borderColor: 'grey', mt: 1, mb: 1, borderWidth: '1px' }} />
                    </Grid>
                    {/* Question 14 */}
                    <Grid item xs={12} sm={4}>
                        <Stack direction="column">
                            <Typography variant="span2" id="nomineeShareholder">
                                <i>By definition product risk is low</i>
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={4}></Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            id="customerIntroduction"
                            name="customerIntroduction"
                            label="Result"
                            value="Low"
                            disabled={allReadOnly}
                            readOnly
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Stack direction="column">
                            <Typography variant="h6" id="nomineeShareholder">
                                The customer incoming transaction amount within 1 calendar month has exceeded EUR 500.000 during review period.
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Autocomplete
                            disabled={allReadOnly}
                            disablePortal
                            id="questionNine"
                            options={[{value: 0, label: "Yes"}, {
                                value: 1,
                                label: "No"
                            }, {value: 2, label: "Onboarding"}]}
                            getOptionLabel={(option) => option.label}
                            filterSelectedOptions
                            onChange={(event, value) => handleQuestion15(value)}
                            size="small"
                            renderInput={(params) => <TextField {...params} required />}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            id="customerIntroduction"
                            name="customerIntroduction"
                            label="Result"
                            value={question15Result}
                            readOnly
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6">SUB CATEGORY RISK</Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}></Grid>
                    {showSubCategory4 === false && (
                        <Grid item xs={12} sm={4}></Grid>
                    )}
                    {showSubCategory4 === true && (
                        <Grid item xs={12} sm={4}>
                            <TextField
                                id="customerIntroduction"
                                name="customerIntroduction"
                                label="Sub Category Risk"
                                value={subCategory4}
                                disabled={allReadOnly}
                                readOnly
                                size="small"
                            />
                        </Grid>
                    )}
                    <Grid item xs={4}>
                        <Typography variant="h6" >
                            Comment
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            id="customerIntroduction"
                            name="customerIntroduction"
                            label="Comment"
                            disabled={allReadOnly}
                            value={comment5}
                            onChange={(e)=> setComment5(e.target.value)}
                            readOnly
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}></Grid>
                    <Grid item xs={12} sm={12}></Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h5">FINAL RISK</Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}></Grid>
                    {showFinalRisk === false && (
                        <Grid item xs={12} sm={4}>
                            <TextField
                                id="customerIntroduction"
                                name="customerIntroduction"
                                label="Final Risk"
                                value="PLEASE ANSWER ALL QUESTIONS"
                                disabled={allReadOnly}
                                readOnly
                                size="small"
                            />
                        </Grid>
                    )}
                    {showFinalRisk === true && (
                        <Grid item xs={12} sm={4}>
                            <TextField
                                id="customerIntroduction"
                                name="customerIntroduction"
                                label="Final Risk"
                                value={finalRisk}
                                disabled={allReadOnly}
                                readOnly
                                size="small"
                            />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Stack direction="row" justifyContent="flex-end">
                            <AnimateButton>
                                <Button variant="contained" sx={{ my: 3, ml: 1 }} type="submit" onClick={handleSave} disabled={showSaveButton === false ? true : false}>
                                    Save
                                </Button>
                            </AnimateButton>
                            <AnimateButton>
                                <Button variant="contained" sx={{ my: 3, ml: 1 }} type="submit" onClick={handleCommit} disabled={showCommitButton === false ? true : false}>
                                    Commit
                                </Button>
                            </AnimateButton>
                            {showApproveButton === true && (
                                <AnimateButton>
                                    <Button variant="contained" sx={{ my: 3, ml: 1 }} type="submit" onClick={handleApprove} disabled={showApproveButton === false ? true : false}>
                                        Approve
                                    </Button>
                                </AnimateButton>
                            )}
                        </Stack>
                        <Stack direction="column" justifyContent="flex-end">
                            {showFillerName === true && (
                                <Typography variant="subtitle1">
                                    <i>Rated By: Damla on {currentTimeForSave.toLocaleDateString()} {currentTimeForSave.toLocaleTimeString()}</i>
                                </Typography>
                            )}
                            {showApproverName === true && (
                                <Typography variant="subtitle1">
                                    <i>Approved By: Cengiz on {currentTimeForApprove.toLocaleDateString()} {currentTimeForApprove.toLocaleTimeString()}</i>
                                </Typography>
                            )}
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        </MainCard>
    );
};

RiskManagement.propTypes = {
    customerData: PropTypes.object,
    setCustomerInitialData: PropTypes.func,
    handleNext: PropTypes.func,
    setErrorIndex: PropTypes.func
};

export default RiskManagement;
