import React, { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import RiskManagement from "../views/dashboard/Default/tabs/forms/RiskManagement";

// dashboard routing
const Landing = Loadable(lazy(() => import('views/pages/landing')));
// const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const Wizard = Loadable(lazy(() => import('views/forms/forms-wizard/index')));
// ===========================|| MAIN ROUTING ||=========================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/welcome',
            element: <Landing />
        },
        {
            path: '/questionaire',
            element: <Wizard />
        },
        {
            path: '/profiling',
            element: <RiskManagement />
        }
    ]
};

export default MainRoutes;
