import React from 'react';
import { Link } from 'react-router-dom';

// material-ui
import { makeStyles } from '@material-ui/styles';
import { Avatar, Box, ButtonBase, Typography } from '@material-ui/core';

import ProfileSection from './ProfileSection';
// assets
import { IconMenu2 } from '@tabler/icons';
import { jwtDecode } from 'jwt-decode';
import Logo from '../../../assets/images/logo.png';

// style constant
const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1
    },
    headerAvatar: {
        ...theme.typography.commonAvatar,
        ...theme.typography.mediumAvatar,
        transition: 'all .2s ease-in-out',
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
        color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
        '&:hover': {
            background: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
            color: theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.light
        }
    },
    boxContainer: {
        width: '228px',
        display: 'flex',
        [theme.breakpoints.down('md')]: {
            width: 'auto'
        }
    },
    logo: {
        height: '70px',
        width: '214px'
    },
    title: {
        display: 'flex',
        marginLeft: '10px',
        justifyContent: 'center',
        width: '100%'
    },
    profile: {
        width: '228px',
        minWidth: '214px',
        alignItems: 'center',
        justifyContent: 'end'
    }
}));

const Header = ({ handleLeftDrawerToggle }) => {
    const classes = useStyles();
    const serviceToken = window.localStorage.getItem('serviceTokenAdmin');
    const decoded = serviceToken && jwtDecode(serviceToken);

    return (
        <>
            {/* logo & toggler button */}
            <div style={{ display: 'flex', width: '100%'}}>
                <div className={classes.boxContainer}>
                    <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                        <ButtonBase disableRipple href={'https://garantibank.nl/'}>
                            <img src={Logo} alt="logo" className={classes.logo} />
                        </ButtonBase>
                    </Box>
                </div>
                <div className={classes.title}>
                    <Typography paragraph variant="h6" noWrap style={{ fontSize: 18, marginTop: 16 }} align="center">
                    </Typography>
                </div>
                {/* header search */}
                <div className={classes.profile}>
                    <ProfileSection />
                </div>
            </div>
        </>
    );
};

export default Header;
